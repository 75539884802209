import React, { ReactNode, useState } from "react";
import {UiTable} from '../../../shared/components/ui/UiTable/UiTable';
import {tableHeads} from './UiListViewHead'
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { IPropsDashboardBase } from '../../interface';
import { dashboardRowPerPage } from '../../../shared/config/GlobalAppConfig';
import {urlRedirectionToEventReadOnly} from '../../../shared/config/GlobalAppConfig';
import LoadingContext from '../../../routes/LoadingContext';
import {ISort, IUiTableHead} from '../../../shared/components/ui/UiTable/types';
import { PAGE_NUMBER_KEY } from "../../../shared/constants";


type UiListViewProps = {
    items: any[]
    loading?: boolean
    error?: boolean | string | ReactNode
    rowClasses?: (item: any) => string
    isVisible?: boolean | undefined
    keepPagination: boolean
    sortColumnHandler?: (item: any) => any
    sort?: ISort
}

export const UiListView = (props:UiListViewProps) =>{
    const ctx = React.useContext(LoadingContext);
    const {items, loading, error} = props

    const history = useHistory();
    const [showAllText, setShowAllText] = useState<string>("Show all events")
    const [rowPerPages, setRowPerPages] = useState<number>(dashboardRowPerPage)
    const [keepPagination, setKeepPagination] = useState<boolean>(props.keepPagination)

    const handleCellClick = (event: IPropsDashboardBase) => {
        ctx.startLoading();
        history.push(urlRedirectionToEventReadOnly + event.id);
    }
    const toggleShowAll = () => {
        if(rowPerPages === dashboardRowPerPage){
            sessionStorage.setItem(PAGE_NUMBER_KEY, (1).toString());
            setRowPerPages(items.length)
            setShowAllText("Show less events")
        }else{
            setRowPerPages(dashboardRowPerPage)
            setShowAllText("Show all events")
        }
    }

    const getHeads = (): IUiTableHead[] => {
        let defaultHeads = [...tableHeads];

        if (props.isVisible) {
            const indexOfCcolumn = defaultHeads.findIndex((header) => header.label === 'C');
            if (indexOfCcolumn !== -1) {
                defaultHeads.splice(indexOfCcolumn, 1);
            }
        }

        return defaultHeads;
    }

    return (
        <>
            {error && <>{error}</>}
            {!error &&
                <UiTable
                    className="dashboard-table"
                    items={items}
                    loading={loading}
                    keepPagination={keepPagination}
                    rowClasses={props.rowClasses}
                    rowPerPages={rowPerPages}
                    action={<Button color="secondary" variant="outlined" onClick={toggleShowAll}>{showAllText}</Button>}
                    tableHeads={getHeads()}
                    onRowSelect={handleCellClick}
                    sortColumnHandler={props.sortColumnHandler}
                    sort={props.sort}
                />
            }
        </>
    )
}