import React, { useEffect, useState, useCallback } from "react";
import {
    EAlertStatus,
    TDuplicateEvent,
    TImportEventResponse,
} from "../../../shared/services/massImportLVL1/massImportService";
import { Box, Checkbox, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { numberScaleFormat } from "../../../shared/helpers/HelpersFunc";

interface IProcessedDataDisplayProps {
    data: TImportEventResponse;
    onDuplicateSelection: (selectedEvents: TDuplicateEvent[]) => void;
}

interface IGroupedDuplicates {
    [key: number]: {
        events: TDuplicateEvent[];
        selected: number[];
    };
}

const ImportedDataDisplay: React.FC<IProcessedDataDisplayProps> = ({ data, onDuplicateSelection }) => {
    const [groupedDuplicates, setGroupedDuplicates] = useState<IGroupedDuplicates>({});

    const { status, eventsWithError, duplicateEvents, totalEventsWithError } = data;

    useEffect(() => {
        if (status === EAlertStatus.Duplicate) {
            const grouped = duplicateEvents.reduce<IGroupedDuplicates>((acc, event) => {
                const key = event.groupId;
                if (!acc[key]) {
                    acc[key] = { events: [], selected: [] };
                }

                acc[key].events.push(event);
                return acc;
            }, {});
            setGroupedDuplicates(grouped);
        }
    }, [status, duplicateEvents]);

    const handleCheckboxChange = useCallback(
        (groupId: number, excelRowNumber: number | null) => {
            if (excelRowNumber === null) return;

            setGroupedDuplicates((prev) => {
                const currentSelected = prev[groupId].selected;
                const newSelected = currentSelected.includes(excelRowNumber)
                    ? currentSelected.filter((num) => num !== excelRowNumber)
                    : [...currentSelected, excelRowNumber];

                const updatedGroup = {
                    ...prev[groupId],
                    selected: newSelected,
                };

                const newGroupedDuplicates = {
                    ...prev,
                    [groupId]: updatedGroup,
                };

                const selectedEvents = Object.values(newGroupedDuplicates).flatMap((group) =>
                    group.events.filter(
                        (event) => event.excelRowNumber !== null && group.selected.includes(event.excelRowNumber)
                    )
                );

                onDuplicateSelection(selectedEvents);

                return newGroupedDuplicates;
            });
        },
        [onDuplicateSelection]
    );

    const formatDate = useCallback((dateString: string) => {
        const date = new Date(dateString);
        return format(date, "dd MMM yyyy");
    }, []);

    const hasDisplayableData = (data: TImportEventResponse) => {
        return data.eventsWithError.length > 0 || data.duplicateEvents.length > 0;
    };

    if (!data || !hasDisplayableData(data)) return null;

    const containerModifiers =
        data.status === EAlertStatus.Error ? "processed-data--error" : "processed-data--duplicate";

    return (
        <section className="modal-import-lvl1__processed-data">
            <div className={`processed-data ${containerModifiers}`}>
                {data.status === EAlertStatus.Error && eventsWithError.length > 0 ? (
                    <>
                        <h4>{totalEventsWithError} errors have been spotted</h4>
                        <ul>
                            {eventsWithError.map((error, index) => (
                                <li key={index}>
                                    Line {error.excelRowNumber}: {error.customErrorMessage}
                                </li>
                            ))}
                        </ul>
                    </>
                ) : null}
                {data.status === EAlertStatus.Duplicate && duplicateEvents.length > 0 ? (
                    <>
                        <Typography variant="body1" style={{ marginBottom: "16px" }}>
                            An event is considered as duplicate if at least 3 of the following values are identical to
                            another existing event or another line in the import file : Country, Maison, Start Date, End
                            Date, Values (Cost in Euro).
                        </Typography>
                        {Object.entries(groupedDuplicates).map(([groupId, group], groupIndex) => (
                            <Box key={groupId} className="duplicate_group" mb={2}>
                                {group.events.map((event: TDuplicateEvent) => (
                                    <Box
                                        key={`${groupId}-${event.excelRowNumber}`}
                                        p={1}
                                        display="flex"
                                        alignItems="center"
                                        className={`duplicate_group__item
                                            ${
                                                group.selected.includes(event.excelRowNumber ?? -1)
                                                    ? "duplicate_group__selected"
                                                    : ""
                                            }   
                                            ${!event.excelRowNumber ? "duplicate_group__existing-event" : ""}
                                        `}
                                    >
                                        <Checkbox
                                            color="primary"
                                            checked={group.selected.includes(event.excelRowNumber ?? -1)}
                                            onChange={() => handleCheckboxChange(Number(groupId), event.excelRowNumber)}
                                            disabled={event.excelRowNumber === null}
                                        />
                                        <Box ml={2}>
                                            <Typography variant="body2">
                                                {event.excelRowNumber
                                                    ? `Line ${event.excelRowNumber}`
                                                    : "Existing event"}
                                            </Typography>
                                            <Typography variant="body1">
                                                {event.eventName}, {formatDate(event.fromDate)} -{" "}
                                                {formatDate(event.toDate)}
                                            </Typography>
                                            <Typography variant="body2">
                                                {event.leadMaison}, {event.country}, {event.eventType},
                                                {" € " + numberScaleFormat(Number(event.estimatedTotalValue))}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                                {groupIndex < Object.keys(groupedDuplicates).length - 1 && <Divider />}
                            </Box>
                        ))}
                    </>
                ) : null}
            </div>
        </section>
    );
};

export default ImportedDataDisplay;
