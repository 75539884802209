import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Popper, Grow, ClickAwayListener, MenuList, MenuItem, Paper } from "@material-ui/core";
import "./UiDropdownButton.scss";

type PropsUiDropdownButton = {
    actions: UiDropdownButtonAction[];
    onClick?(event: any, actionName: string): void;
    onChange?(event: any, actionName: string): void;
    default: number;
    disabled?: boolean;
    loading?: boolean;
};

type UiDropdownButtonAction = {
    name: string;
    label: string;
    onClick?: () => void;
};

export const UiDropdownButton = (props: PropsUiDropdownButton) => {
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(props.default);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        if (!open) {
            // Unselect the item after the action is done
            setSelectedIndex(-1);
        }
    };

    const handleMenuItemClick = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
        setOpen(false);

        try {
            const action = props.actions[index];

            // if the action has a specific onClick, we execute it
            if (action.onClick && typeof action.onClick === "function") {
                await action.onClick();
            }
            // Otherwise, we use the global onClick
            else if (props.onClick) {
                await props.onClick(event, action.name);
            }

            if (props.onChange) {
                props.onChange(event, action.name);
            }
        } finally {
            setSelectedIndex(-1); // Unselect the item after the action is done
        }
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
        setSelectedIndex(-1); // Unselect the item after the action is done
    };

    return (
        <div onClick={(e: any) => e.stopPropagation()}>
            <Button
                ref={anchorRef}
                variant="contained"
                className="ui-dropdown-button"
                onClick={handleToggle}
                disabled={props.disabled}
                endIcon={<ArrowDropDownIcon />}
            >
                {props.loading ? "Loading..." : "Export/Import"}
            </Button>
            <Popper
                className="ui-dropdown-button__popper"
                open={open}
                anchorEl={anchorRef.current}
                role="menuitem"
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="dropdown-button-menu">
                                    {props.actions.map((action, index) => (
                                        <MenuItem
                                            key={index}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                            disabled={props.loading}
                                        >
                                            {action.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default UiDropdownButton;
