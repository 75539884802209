import axios, { AxiosRequestConfig } from "axios";
import CustomAxios, { ApiCallingMethods } from "../../helpers/AxiosHelper";
import {
    urlMassImportServiceImportEvents,
    urlMassImportServiceGetImportedFiles,
    urlMassImportServiceAddDuplicateEvents,
    urlMassImportServiceRollbackImportedFile,
} from "../../config/GlobalAppConfig";

import * as yup from "yup";

/** Enum representing possible custom statuses returned by the API */
export enum EApiResponseCustomStatus {
    FileImportedWithSuccess = "FileImportedWithSuccess",
    ErrorsInFile = "ErrorsInFile",
    DuplicateData = "DuplicateData",
    DuplicateEventsAddedSuccessfully = "DuplicateEventsAddedSuccessfully",
    InvalidFileFormat = "InvalidFileFormat",
    ImportedFilesSuccessfullyRetrieved = "ImportedFilesSuccessfullyRetrieved",
    ImportedFileSuccessfullyRollbacked = "ImportedFileSuccessfullyRollbacked",
}

/** Enum representing possible alert statuses for the UI */
export enum EAlertStatus {
    Success = "success",
    Error = "error",
    Duplicate = "duplicate",
    Unknown = "unknown",
}

/** Type representing possible API response status codes */
export type TApiResponseStatusCode = "200" | "409" | "420" | "422" | "500";

/** Interface for Event Error */
export interface TEventError {
    excelRowNumber: number;
    customErrorMessage: string;
}

/** Interface for Duplicate Event */
export interface TDuplicateEvent {
    excelRowNumber: number | null;
    groupId: number;
    isDuplicateInDatabase: boolean;
    leadMaison: string;
    country: string | number;
    eventName: string;
    eventType: string | number;
    fromDate: string;
    toDate: string;
    eventAddress: string;
    estimatedTotalValue: number;
    importedFileId: number | null;
    isImportedEvent: boolean | null;
}

/** Interface for API Response Model */
export interface TApiResponseModel {
    customStatus: EApiResponseCustomStatus;
    customStatusCode: TApiResponseStatusCode;
}

/** Interface for Base API Response */
export interface TApiResponseBase {
    message: string | null;
    didError: boolean;
    errorMessage: string | null;
    model: TApiResponseModel;
}

/** Interface for Import API Response Model */
export interface TApiResponseImportModel extends TApiResponseModel {
    importedFileId: number | null;
    totalImportedEvents: number;
    totalDuplicateEvents: number;
    totalEventsWithError: number;
    eventsWithError: TEventError[];
    duplicateEvents: TDuplicateEvent[];
}

/** Interface for Import API Response */
export interface TApiResponseImport extends TApiResponseBase {
    model: TApiResponseImportModel;
}

/** Interface for Duplicate Events API Response Model */
export interface TApiResponseDuplicateModel extends TApiResponseModel {
    totalImportedDuplicateEvents: number;
}

/** Interface for Duplicate Events API Response */
export interface TApiResponseDuplicate extends TApiResponseBase {
    model: TApiResponseDuplicateModel;
}

/** Interface for Standardized Status Response */
export interface TStandardizedStatusResponse {
    message: string | null;
    didError: boolean;
    errorMessage: string | null;
    customStatus: EApiResponseCustomStatus;
    customStatusCode: TApiResponseStatusCode;
    status: EAlertStatus;
    statusCode: TApiResponseStatusCode;
}

/** Interface for Import Event Response */
export interface TImportEventResponse extends TStandardizedStatusResponse {
    importedFileId: number | null;
    totalImportedEvents: number;
    totalDuplicateEvents: number;
    totalEventsWithError: number;
    eventsWithError: TEventError[];
    duplicateEvents: TDuplicateEvent[];
}

/** Interface for Add Duplicate Events Response */
export interface TAddDuplicateEventsResponse extends TStandardizedStatusResponse {
    totalImportedDuplicateEvents: number;
}

/** Type for imported file metadata */
export interface TImportedFile {
    id: number;
    isImportRollbacked: boolean;
    fileName: string;
    createdDate: string;
    createdBy: string;
    eventsCount: number;
}

/** Interface for API Response Model with Imported Files */
export interface TImportedFilesModelResponse extends TApiResponseModel {
    importedFiles: TImportedFile[];
    rollabackedFile: TImportedFile | null;
}

/** Interface for API Response including imported files */
export interface TImportedFilesResponse extends TApiResponseBase {
    model: TImportedFilesModelResponse;
}

/** Interface for Rollback Imported File Response */
export interface TRollbackImportedFileResponse extends TStandardizedStatusResponse {
    model: {
        customStatus: EApiResponseCustomStatus;
        customStatusCode: TApiResponseStatusCode;
        importedFiles: null;
        rollabackedFile: TImportedFile;
    };
}

/** Base Yup Schemas for Reuse */
const yApiResponseCustomStatusSchema = yup
    .mixed<EApiResponseCustomStatus>()
    .oneOf(Object.values(EApiResponseCustomStatus))
    .required();

const yApiResponseStatusCodeSchema = yup
    .mixed<TApiResponseStatusCode>()
    .oneOf(["200", "409", "420", "422", "500"])
    .required();

/** Yup Schema for an imported file */
const yImportedFileSchema = yup.object().shape({
    id: yup.number().required(),
    isImportRollbacked: yup.boolean().required(),
    fileName: yup.string().required(),
    createdDate: yup.string().required(),
    createdBy: yup.string().required(),
    eventsCount: yup.number().required(),
});

/** Yup Schema for the API Response Model with Imported Files */
const yApiResponseImportedFilesModelSchema = yup.object().shape({
    customStatus: yApiResponseCustomStatusSchema,
    customStatusCode: yApiResponseStatusCodeSchema,
    importedFiles: yup.array().of(yImportedFileSchema).required(),
    rollabackedFile: yImportedFileSchema.nullable(),
});

/** Overall Yup Schema for Imported Files Response */
const yApiResponseImportedFilesSchema = yup.object().shape({
    message: yup.string().nullable(),
    didError: yup.boolean().required(),
    errorMessage: yup.string().nullable(),
    model: yApiResponseImportedFilesModelSchema.required(),
});

/** Yup Schema for Event Error */
const yEventErrorSchema = yup.object().shape({
    excelRowNumber: yup.number().required(),
    customErrorMessage: yup.string().required(),
});

/** Yup Schema for Duplicate Event */
const yDuplicateEventSchema = yup.object().shape({
    excelRowNumber: yup.number().nullable(),
    groupId: yup.number().required(),
    isDuplicateInDatabase: yup.boolean().required(),
    leadMaison: yup.string().nullable(),
    country: yup.string().nullable(),
    eventName: yup.string().nullable(),
    eventType: yup.string().nullable(),
    fromDate: yup.string().nullable(),
    toDate: yup.string().nullable(),
    eventAddress: yup.string().nullable(),
    estimatedTotalValue: yup.number().nullable(),
    importedFileId: yup.number().nullable(),
    isImportedEvent: yup.boolean().nullable(),
});

/** Yup Schema for Import API Response Model */
const yApiResponseImportModelSchema = yup.object().shape({
    customStatus: yApiResponseCustomStatusSchema,
    customStatusCode: yApiResponseStatusCodeSchema,
    importedFileId: yup.number().nullable(),
    totalImportedEvents: yup.number().required(),
    totalDuplicateEvents: yup.number().required(),
    totalEventsWithError: yup.number().required(),
    eventsWithError: yup.array().of(yEventErrorSchema).nullable().default([]),
    duplicateEvents: yup.array().of(yDuplicateEventSchema).nullable().default([]),
});

/** Yup Schema for Import API Response */
const yApiResponseImportSchema = yup.object().shape({
    message: yup.string().nullable(),
    didError: yup.boolean().required(),
    errorMessage: yup.string().nullable(),
    model: yApiResponseImportModelSchema.required(),
});

/** Yup Schema for Duplicate Events API Response Model */
const yApiResponseDuplicateModelSchema = yup.object().shape({
    customStatus: yApiResponseCustomStatusSchema,
    customStatusCode: yApiResponseStatusCodeSchema,
    totalImportedDuplicateEvents: yup.number().required(),
});

/** Yup Schema for Duplicate Events API Response */
const yApiResponseDuplicateSchema = yup.object().shape({
    message: yup.string().nullable(),
    didError: yup.boolean().required(),
    errorMessage: yup.string().nullable(),
    model: yApiResponseDuplicateModelSchema.required(),
});

// Define the Yup schema for the rollback imported file with nullable eventsCount
const yRollbackImportedFileSchema = yup.object().shape({
    id: yup.number().required(),
    isImportRollbacked: yup.boolean().required(),
    fileName: yup.string().required(),
    createdDate: yup.string().required(),
    createdBy: yup.string().required(),
    eventsCount: yup.number().nullable(),
});

/** Yup Schema for Rollback Response */
const yRollbackResponseSchema = yup.object().shape({
    message: yup.string().nullable(),
    didError: yup.boolean().required(),
    errorMessage: yup.string().nullable(),
    model: yup
        .object()
        .shape({
            customStatus: yApiResponseCustomStatusSchema,
            customStatusCode: yApiResponseStatusCodeSchema,
            importedFiles: yup.array().nullable(),
            rollabackedFile: yRollbackImportedFileSchema.required(),
        })
        .required(),
});

export class MassImportService {
    /**
     * Imports events from an Excel file.
     * @param {File} file - The Excel file containing event data to import.
     * @returns {Promise<TImportEventResponse>} A promise that resolves to the import response.
     */
    static async importEvents(file: File): Promise<TImportEventResponse> {
        const excelFile = new FormData();
        excelFile.append("file", file);

        const response = await this.makeRequest<TApiResponseImport>(
            {
                method: "POST",
                url: urlMassImportServiceImportEvents,
                data: excelFile,
                headers: { "Content-Type": "multipart/form-data" },
            },
            yApiResponseImportSchema
        );

        // Create the base response using common fields
        const baseResponse = this.createBaseResponse(response);

        // Merge additional fields from response.model into the final response
        return {
            ...baseResponse,
            importedFileId: response.model.importedFileId,
            totalImportedEvents: response.model.totalImportedEvents,
            totalDuplicateEvents: response.model.totalDuplicateEvents,
            totalEventsWithError: response.model.totalEventsWithError,
            eventsWithError: response.model.eventsWithError ?? [],
            duplicateEvents: response.model.duplicateEvents ?? [],
        };
    }

    /**
     * Retrieves all previously imported files.
     * @returns {Promise<TImportedFilesResponse>} A promise that resolves to a standardized response with imported files.
     */
    static async getAllImportedFiles(): Promise<TImportedFilesResponse> {
        const response = await this.makeRequest<TImportedFilesResponse>(
            {
                method: ApiCallingMethods.get,
                url: urlMassImportServiceGetImportedFiles,
                headers: { "Content-Type": "application/json" },
            },
            yApiResponseImportedFilesSchema
        );

        // Create the base response using common fields
        const baseResponse = this.createBaseResponse(response);

        return {
            ...baseResponse,
            model: {
                ...response.model,
                importedFiles: response.model.importedFiles,
                rollabackedFile: response.model.rollabackedFile,
            },
        };
    }

    /**
     * Adds duplicate events to the database.
     * @param {TDuplicateEvent[]} duplicates - The duplicate events to add.
     * @returns {Promise<TAddDuplicateEventsResponse>} A promise that resolves to a standardized response.
     */
    static async addDuplicateEvents(duplicates: TDuplicateEvent[]): Promise<TAddDuplicateEventsResponse> {
        const response = await this.makeRequest<TApiResponseDuplicate>(
            {
                method: ApiCallingMethods.post,
                url: urlMassImportServiceAddDuplicateEvents,
                data: duplicates,
                headers: { "Content-Type": "application/json" },
            },
            yApiResponseDuplicateSchema
        );

        // Create the base response using common fields
        const baseResponse = this.createBaseResponse(response);

        // Merge additional fields from response.model into the final response
        return {
            ...baseResponse,
            totalImportedDuplicateEvents: response.model.totalImportedDuplicateEvents,
        };
    }

    /**
     * Rolls back an imported file.
     * @param importedFile The full imported file object to roll back.
     * @returns A promise that resolves to a standardized response indicating the rollback status.
     */
    static async rollbackImportedFile(importedFile: TImportedFile): Promise<TRollbackImportedFileResponse> {
        const response = await this.makeRequest<TRollbackImportedFileResponse>(
            {
                method: ApiCallingMethods.post,
                url: urlMassImportServiceRollbackImportedFile,
                data: importedFile,
                headers: { "Content-Type": "application/json" },
            },
            yRollbackResponseSchema
        );

        // Create the base standardized response
        const baseResponse = this.createBaseResponse(response);

        console.log("Rollback response:", {
            ...baseResponse,
            model: response.model,
        });

        return {
            ...baseResponse,
            model: response.model,
        };
    }

    /**
     * Makes a request to the API and processes the response.
     * @template T The type of the expected API response schema
     * @param {AxiosRequestConfig} config - The Axios request configuration.
     * @param {yup.Schema<any>} schema - The Yup schema to validate the response.
     * @returns {Promise<T>} A promise that resolves to a validated API response.
     */
    private static async makeRequest<T>(config: AxiosRequestConfig, schema: yup.Schema<any>): Promise<T> {
        try {
            const response = await CustomAxios.requestAsPromise(config);

            const validatedData = this.processApiResponse<T>(response.data, schema);

            return validatedData;
        } catch (error) {
            console.error("Error during API request:", error);
            if (axios.isAxiosError(error) && error.response) {
                console.error("API response error:", error.response.data);
                const fallbackResponse = this.processApiResponse<T>(error.response.data, schema);
                console.log("Fallback validated API response: ", fallbackResponse);
                return fallbackResponse;
            }
            throw new Error(
                `Network error or server unavailable. ${axios.isAxiosError(error) ? error.message : "Unknown error"}`
            );
        }
    }

    /**
     * Processes the API response and standardizes it.
     * @template T The type of the expected API response schema
     * @param {unknown} data - The raw API response data.
     * @param {yup.Schema<any>} schema - The Yup schema to validate the response.
     * @returns {T} A validated API response.
     */
    private static processApiResponse<T>(data: unknown, schema: yup.Schema<any>): T {
        try {
            const validatedData = schema.validateSync(data, { strict: true, abortEarly: false });
            return validatedData as T;
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                console.error("Yup validation errors:", error.errors);
            }
            throw new Error("Invalid API response format");
        }
    }

    /**
     * Creates a standardized base response.
     * @param {TApiResponseBase} apiResponse - The API response.
     * @returns {TStandardizedStatusResponse} - The standardized base response.
     */
    private static createBaseResponse(apiResponse: TApiResponseBase): TStandardizedStatusResponse {
        const status = this.determineAlertStatus(apiResponse.model.customStatus);
        const generatedMessage = this.createSuccessMessage(apiResponse.model);

        return {
            message: generatedMessage,
            didError: apiResponse.didError,
            errorMessage: apiResponse.errorMessage,
            customStatus: apiResponse.model.customStatus,
            customStatusCode: apiResponse.model.customStatusCode,
            status,
            statusCode: apiResponse.model.customStatusCode,
        };
    }

    /**
     * Determines the appropriate alert status based on the API custom status.
     * @param {EApiResponseCustomStatus} customStatus - The custom status from the API.
     * @returns {EAlertStatus} The corresponding alert status.
     */
    private static determineAlertStatus(customStatus: EApiResponseCustomStatus): EAlertStatus {
        switch (customStatus) {
            case EApiResponseCustomStatus.FileImportedWithSuccess:
            case EApiResponseCustomStatus.DuplicateEventsAddedSuccessfully:
                return EAlertStatus.Success;
            case EApiResponseCustomStatus.ErrorsInFile:
            case EApiResponseCustomStatus.InvalidFileFormat:
                return EAlertStatus.Error;
            case EApiResponseCustomStatus.DuplicateData:
                return EAlertStatus.Duplicate;
            default:
                console.warn(`Unexpected custom status: ${customStatus}`);
                return EAlertStatus.Unknown;
        }
    }

    /**
     * Creates a human-readable success message based on the API response model.
     * @param {TApiResponseModel & Partial<TApiResponseImportModel>} model - The API response model.
     * @returns {string} A human-readable success message.
     */
    private static createSuccessMessage(model: TApiResponseModel & Partial<TApiResponseImportModel>): string {
        switch (model.customStatus) {
            case EApiResponseCustomStatus.FileImportedWithSuccess:
                return `File imported successfully. ${
                    model.totalImportedEvents ?? "Unknown number of"
                } event(s) successfully imported.`;
            case EApiResponseCustomStatus.ErrorsInFile:
                return `No event was imported, please correct the error(s) and try again.`;
            case EApiResponseCustomStatus.DuplicateData:
                return `${model.totalDuplicateEvents ?? "Unknown number of"} duplicate(s) have been spoted.`;
            case EApiResponseCustomStatus.DuplicateEventsAddedSuccessfully:
                return `Duplicate events added successfully.`;
            case EApiResponseCustomStatus.InvalidFileFormat:
                return `Invalid file format, please use the provided Excel template and check that your file is not encrypted.`;
            default:
                return "Unknown status.";
        }
    }
}
