import React, { useState } from "react";
import { IStateView } from "./Dashboard";
import { UiTextField } from "../shared/components/fields";
import SearchIcon from "@material-ui/icons/Search";
import { ButtonGroup, Button } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddIcon from "@material-ui/icons/Add";
import { ModalFilters } from "./components/filters/ModalFilter";
import { Reference } from "../shared/services/referencesService";
import { IModalFilter } from "./interface";
import { DashboardService } from "../shared/services/dashboardService";
import * as XLSX from "xlsx";
import { UserRoles } from "../shared/models/enums";
import { getMappingRoles } from "../shared/models/records";
import LocalStorageTokenService from "../shared/helpers/SessionStorageTokenService";
import { LoggingServie } from "../shared/services/LoggingService";
import { insuranceRoles } from "../shared/config/securityConcept";
import { useHistory } from "react-router-dom";
import UiDropdownButton from "../shared/components/ui/UiDropdownButton";
import ModalImportLVL1 from "./Modals/MassImportLVL1/ModalImportLVL1";
import {
    TAddDuplicateEventsResponse,
    TDuplicateEvent,
    TImportedFile,
    TImportedFilesResponse,
    TImportEventResponse,
    TRollbackImportedFileResponse,
} from "../shared/services/massImportLVL1/massImportService";
import ModalHistoryImportedLVL1 from "./Modals/MassImportLVL1/ModalHistoryImportedLVL1";

type IPropsDashboardActions = {
    setSearchQuery(searchQuery: string): void;
    setModalFilter(modalFilterValue: IModalFilter): void;
    setView(view: IStateView): void;
    modalFilter: IModalFilter;
    view: IStateView;
    searchQuery: string;
    coutriesRef: Reference[];
    riskLevelRef: Reference[];
    eventStatusListRef: Reference[];
    maisonRef: Reference[];
};
export const DashboardActions = (props: IPropsDashboardActions) => {
    const idrole = getMappingRoles(LocalStorageTokenService.getUserRoleToken());

    const [isModalImportLVL1Open, setModalImportLVL1Open] = useState(false);
    const [isModalHistoryImportLVL1Open, setModalHistoryImportLVL1Open] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const history = useHistory();

    /**
     * Handles the import of level 1 events by delegating the request to DashboardService.
     * The response is standardized by MassImportService, including error handling.
     *
     * @param {File} file - The Excel file to be uploaded for mass import of events.
     * @returns {Promise<TImportEventResponse>} A promise that resolves to a structured response containing
     * the result of the import, including the status, message, and potential errors or duplicates.
     */
    const handleImportLVL1 = async (file: File): Promise<TImportEventResponse> => {
        return await DashboardService.handleImportLVL1(file);
    };

    const handleDuplicateSelection = async (
        selectedEvents: TDuplicateEvent[]
    ): Promise<TAddDuplicateEventsResponse> => {
        return await DashboardService.handleImportDuplicateLVL1(selectedEvents);
    };

    const fetchImportedFiles = async (): Promise<TImportedFilesResponse> => {
        return await DashboardService.getAllImportedFiles();
    };

    const handleRollbackClick = async (importedFile: TImportedFile): Promise<TRollbackImportedFileResponse> => {
        return await DashboardService.rollbackImportedFile(importedFile);
    };

    const handleCloseModalImportLVL1 = () => {
        setModalImportLVL1Open(false);
    };

    const handleCloseModalHistoryImportLVL1 = () => {
        setModalHistoryImportLVL1Open(false);
    };

    const exportImportActions = [
        {
            name: "Export",
            label: "Export (Full)",
        },
        {
            name: "Summary export",
            label: "Export (Summary)",
        },
        {
            name: "import",
            label: "Import (Level 1 events only)",
            onClick: () => {
                setModalImportLVL1Open(true);
            },
        },
        {
            name: "review_import_history",
            label: "Review Import History",
            onClick: () => {
                setModalHistoryImportLVL1Open(true);
            },
        },
    ];

    const updateSearchQuery = (value: string) => {
        props.setSearchQuery(value);
    };
    const handleSearchChange = (event: any) => {
        updateSearchQuery(event.target.value);
    };
    const [modalFilterActive, setModalFilterActive] = useState<boolean>(false);

    const handleCloseModal = () => {
        setModalFilterActive(false);
    };

    const handleOpenModal = () => {
        setModalFilterActive(true);
    };

    const isCurrency = (colName: string): boolean => {
        const matchingTerm = ["value", "Value", "Amount", "amount"];
        for (let term of matchingTerm) {
            if (colName.includes(term)) return true;
        }
        return false;
    };

    const extractHeaders = (ws: XLSX.WorkSheet): string[] => {
        const headers = [];
        const columnCount = XLSX.utils.decode_range(ws["!ref"]!).e.c + 1;
        for (let i = 0; i < columnCount; ++i) {
            headers[i] = ws[`${XLSX.utils.encode_col(i)}1`].v;
        }
        return headers;
    };

    function formatColumn(worksheet: XLSX.WorkSheet, col: number, fmt: string) {
        const range = XLSX.utils.decode_range(worksheet["!ref"]!);
        // note: range.s.r + 1 skips the header row
        for (let row = range.s.r + 1; row <= range.e.r; ++row) {
            const ref = XLSX.utils.encode_cell({ r: row, c: col });
            if (worksheet[ref] && worksheet[ref].t === "n") {
                worksheet[ref].z = fmt;
            }
        }
    }

    const formatCurrency = (ws: XLSX.WorkSheet) => {
        const headers = extractHeaders(ws);
        const indexCurrencyCol = headers
            .map((header, index) => {
                return isCurrency(header) ? index : -1;
            })
            .filter((index) => index >= 0);
        console.warn("indexCurr", indexCurrencyCol);
        const currencyFormat = "€0.00";
        for (let col of indexCurrencyCol) {
            formatColumn(ws, col, currencyFormat);
        }
    };

    const exportData = (event: any, actionName: any) => {
        const exportFilters = props.modalFilter;
        if (actionName == "Summary export") {
            exportFilters.isSummary = true;
        } else {
            exportFilters.isSummary = false;
        }

        setIsExporting(true);

        DashboardService.getEventDeclarationExportData(exportFilters).subscribe(
            (response) => {
                const data = response.data.model;
                console.warn("data", data);
                const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
                formatCurrency(worksheet);
                const workbook: XLSX.WorkBook = { Sheets: { Events: worksheet }, SheetNames: ["Events"] };
                const filename: string = "EventDeclarationExportList.xlsx";
                XLSX.writeFile(workbook, filename);
                setIsExporting(false);
            },
            (error) => {
                LoggingServie.LogError(error, "");
                setIsExporting(false);
            }
        );
    };

    const userCanSeeNewEvent = () => {
        return !insuranceRoles.includes(LocalStorageTokenService.getUserRoleToken() as UserRoles);
    };

    return (
        <div className="dashboard-actions">
            <div className="dashboard-actions__item">
                <ButtonGroup disableElevation={true}>
                    <Button
                        size="medium"
                        color="primary"
                        variant={props.view === "list" ? "contained" : "outlined"}
                        startIcon={<ListIcon />}
                        onClick={() => {
                            props.setView("list");
                            sessionStorage.setItem("view", "list");
                        }}
                    >
                        List view
                    </Button>
                    <Button
                        id="calendarViewBtn"
                        size="medium"
                        color="primary"
                        variant={props.view === "calendar" ? "contained" : "outlined"}
                        startIcon={<DateRangeIcon />}
                        onClick={() => {
                            props.setView("calendar");
                            sessionStorage.setItem("view", "calendar");
                        }}
                    >
                        Calendar
                    </Button>
                </ButtonGroup>
            </div>
            <div className="dashboard-actions__item dashboard-actions__item--search">
                <UiTextField
                    label="search"
                    fullWidth={false}
                    onChange={handleSearchChange}
                    prefix={<SearchIcon />}
                    value={props.searchQuery}
                />
            </div>
            <div className="dashboard-actions__item">
                <Button
                    id="addFiltersBtn"
                    color="secondary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={handleOpenModal}
                >
                    Filters
                </Button>
                <ModalFilters
                    open={modalFilterActive}
                    isListViewFilter={true}
                    onClose={handleCloseModal}
                    onApply={props.setModalFilter}
                    modalFilter={props.modalFilter}
                    coutriesRef={props.coutriesRef}
                    riskLevelRef={props.riskLevelRef}
                    eventStatusListRef={props.eventStatusListRef}
                    maisonRef={props.maisonRef}
                />
            </div>

            <div className="dashboard-actions__item">
                <UiDropdownButton
                    actions={exportImportActions}
                    onClick={(event, actionName) => exportData(event, actionName)}
                    default={0}
                    loading={isExporting}
                />
                {isModalImportLVL1Open ? (
                    <ModalImportLVL1
                        open={isModalImportLVL1Open}
                        onClose={handleCloseModalImportLVL1}
                        onImport={handleImportLVL1}
                        onDuplicateSelection={handleDuplicateSelection}
                    />
                ) : null}
                {isModalHistoryImportLVL1Open ? (
                    <ModalHistoryImportedLVL1
                        open={isModalHistoryImportLVL1Open}
                        fetchHistory={fetchImportedFiles}
                        onClose={handleCloseModalHistoryImportLVL1}
                        onRollback={handleRollbackClick}
                    />
                ) : null}
            </div>

            <div className="dashboard-actions__item dashboard-actions__item--export">
                {userCanSeeNewEvent() && (
                    <Button
                        id="newEventBtn"
                        color="secondary"
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => history.push("/dashboard/event/new")}
                    >
                        New Event
                    </Button>
                )}
            </div>
        </div>
    );
};
