import {Refresh, Search} from '@material-ui/icons';
import React, {FC, useEffect, useMemo, useState} from "react";
import { UiTextField } from "../../../shared/components/fields";
import UiBox from "../../../shared/components/ui/UiBox";
import { Accessor, IUiTableHead } from "../../../shared/components/ui/UiTable/types";
import { UiTable } from "../../../shared/components/ui/UiTable/UiTable";
import { UiTableCellProps } from "../../../shared/components/ui/UiTable/UiTableCell";
import { accessValue } from '../../../shared/components/ui/UiTable/UiTableHelper';
import { adminRowsPerPage } from "../../../shared/config/GlobalAppConfig";
import { AddEditBtn } from "./AddEditItemBtn";
import { AdministrationFilters, AdministrationFiltersProps } from "./AdministrationFilters";
import {UiTableWithPaginationBack} from "../../../shared/components/ui/UiTable/UiTablePaginationBack";
import {FilteredAdminPagedRequest, PagedResponse} from "../../../shared/models/interfaces";
import {DELAY_SEARCH, ROWS_PER_PAGE} from "../../../shared/constants";
import {IconButton} from "@material-ui/core";

type ISearchFunction<T=any> = {
    (item:T, searchVal: string): boolean;
};
export type IAdministrationTableHead<T=any> = IUiTableHead<T> & {
    searchable?: boolean;
    search?:ISearchFunction<T>;
}
type AdministrationTableProps<T = any> = {
    id?: string,
    items: T[],
    tableHeads: IAdministrationTableHead[],
    form: FC<any>,
    onCreate: any,
    onDelete: any,
    onUpdate: any,
    data?: any,
    loading?: boolean,
    title: string,
    itemLabelProp: Accessor<T>,
    filters?: AdministrationFiltersProps,
    className?: string,
    backendPagination?: boolean,
    onPage?: ((request: FilteredAdminPagedRequest) => any),
    pageResponse?: PagedResponse<T>
}

const EditCell = (editProps: UiTableCellProps) => {
    const {head, onChange, index, ...item} = editProps;
    return <AddEditBtn index={index} form={head.form} title={head.modalTitle}  item={item} itemLabelProp={head.itemLabelProp} data={head.data} />;
};

export const AdministrationTable = ({items, filters, id, ...props}: AdministrationTableProps) => {

    const [filteredList, setFilteredList] = useState<any[]>([]);
    const [itemList, setItemList] = useState<any[]>([])
    const [search,setSearch] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [activeFilters, setActiveFilters] = useState<Object>({});
    const handleApplyFilters = (formValue: Object) => {
        setActiveFilters(formValue)
    }
    const [pageRequest, setPageRequest] = useState<FilteredAdminPagedRequest>({
        refreshCache: false,
        sortOrder: undefined,
        pageNumber: 1,
        pageSize: ROWS_PER_PAGE
    });
    const [isRefreshing, setIsRefreshing] = useState<boolean>(true);

    const searchableFields : IAdministrationTableHead<object>[] = useMemo(
        () =>
            props.tableHeads.reduce((accumulator: IAdministrationTableHead<object>[], item) => {
                if (item.searchable || !!item.search) {
                    accumulator.push(item);
                }
                return accumulator;
            }, []),
        [props.tableHeads]
    );

    const doFilters = () => {
        const activeFiltersKeys = Object.keys(activeFilters);
        if(!activeFiltersKeys.length || !filters){
            setFilteredList(items);
            return;
        }
        let filteredListTemp = items.filter(item=>{
            return activeFiltersKeys.every(filterKey=>{
                const filter = filters.items.find(filter=>filter.name === filterKey);
                if(filter && filter.filter){
                    return filter.filter(item, activeFilters[filterKey])
                }
                const accessor = filter?.accessor;
                if(!filter || !accessor || typeof item[accessor] === 'undefined'){
                    console.warn(`${filterKey} on ${accessor} has no effect`);
                    return true;
                }
                const itemValue = item[accessor];
                if(filter.type === "boolean"){
                    return itemValue === activeFilters[filterKey]
                }else if(filter.type === "multiselect"){
                    return !activeFilters[filterKey].length || activeFilters[filterKey].some((f:any)=>f.value === itemValue)
                }else if(filter.type === "select"){
                    return !activeFilters[filterKey] || activeFilters[filterKey].value === itemValue;
                }else{
                    console.warn(`${filterKey} on ${accessor} has no effect`);
                    return true
                }
            })
        });
        setFilteredList(filteredListTemp)
    };

    const doSearch = () => {
        if(!search || !searchableFields.length) {
            setItemList(filteredList);
            return;
        }
        let searchResultList = filteredList.filter((item) =>{
            return searchableFields.some((field) => {
                if(!!field.search){
                    return field.search(item, search)
                }
                if(field.accessor){
                    const value = `${accessValue(item, field.accessor)}`
                    return value.toLowerCase().includes(search.toLowerCase());
                }else if (!!item[field.name].some) {
                    return item[field.name].some((val: any) => !!val.includes && val.toLowerCase().includes(search.toLowerCase()));
                } else if (!!item[field.name].includes) {
                    return item[field.name].toLowerCase().includes(search.toLowerCase());
                } else {
                    console.error(`AdministrationTable not handle search for field "${field.name}" of type: "${typeof item[field.name]}" \n\n value:\n`, item)
                    return false;
                }

            })}
        );
        setItemList(searchResultList);
    }

    const onSearchChange = (e: any) => {
        setSearchQuery(e.target.value);
    }

    const onPageChange = (event:any) => {

        if(event?.pageNumber !== pageRequest.pageNumber ||
            event?.sortOrder !== pageRequest.sortOrder ||
            event?.orderColumn !== pageRequest.orderColumn){
            setPageRequest({...pageRequest,...event, refreshCache: false});
        }
    }

    const refreshCache = () => {
        if(props.loading || pageRequest.refreshCache){
            return;
        }
        setIsRefreshing(true);
        setPageRequest({...pageRequest, pageNumber: 1, refreshCache: true});
    }

    useEffect(() => {
        if (props.backendPagination) {
            setPageRequest({...pageRequest, searchValue: search, pageNumber: 1, refreshCache: false});
        } else {
            doSearch();
        }

    }, [search, filteredList]);

    useEffect(()=> {
        if(!props.backendPagination){
            doFilters();
        }
    },[items]);

    useEffect(() => {
        if (props.backendPagination) {
            const maisonFilters = activeFilters['maisons']?.map((m: any) => m.value);
            const countryFilters = activeFilters['countries']?.map((m: any) => m.value);
            setPageRequest({...pageRequest, maisons: maisonFilters, countries: countryFilters, pageNumber: 1, refreshCache: false});
        } else {
            doFilters();
        }

    }, [activeFilters, searchableFields]);

    useEffect( () => {
        if(props.backendPagination){
            if(props.onPage){
                props.onPage(pageRequest);
            }
        }
    }, [pageRequest]);

    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            setSearch(searchQuery);
        }, DELAY_SEARCH);
        return () => clearTimeout(searchTimeout);
    }, [searchQuery]);

    useEffect(() => {
        if (!props.loading) {
            setIsRefreshing(false);
        }
    }, [props.loading]);

    const form = {
        component: props.form,
        onDelete: props.onDelete,
        onUpdate: props.onUpdate,
        onCreate: props.onCreate,
    }
    const heads = [...props.tableHeads, {
        name: "actions",
        label: "Actions",
        cell: EditCell,
        form,
        modalTitle: props.title,
        itemLabelProp: props.itemLabelProp,
        data: props.data
    }
    ];
    return (
        <UiBox
            id={id}
            name="administration-box"
            actions={
                <div className="head-actions">
                    <div className="head-actions__item">
                        <AddEditBtn form={form} title={props.title} itemLabelProp={props.itemLabelProp} data={props.data} />
                    </div>
                    <div className="head-actions__item">
                        {filters && filters.items.length && <AdministrationFilters items={filters.items} validationSchema={filters.validationSchema} onApply={handleApplyFilters} />}
                    </div>
                    <div className="head-actions__item">
                        <UiTextField
                            id={id+'-search'}
                            label="search"
                            fullWidth={false}
                            onChange={onSearchChange}
                            value={searchQuery}
                            prefix={<Search />}
                        />

                    </div>
                    {props.backendPagination &&
                        <div className="head-actions__item">
                            <IconButton
                                className={"recycle-icon"}
                                title="Refresh Richemont users"
                                color="primary"
                                disabled={isRefreshing || pageRequest.refreshCache}
                                onClick={() => refreshCache()}
                            >
                                <Refresh className={"recycle-icon " + (isRefreshing ? " start-anim" : "")}/>
                            </IconButton>
                        </div>
                    }
                </div>
            }
        >
            {props.backendPagination ?
                <UiTableWithPaginationBack
                    loading={props.loading}
                    className={'admin-table ' + props.className}
                    sorted
                    sortColumn={0}
                    items={itemList}
                    tableHeads={heads}
                    rowPerPages={adminRowsPerPage}
                    handlePageChange={onPageChange}
                    {...props.pageResponse!}
                />
                : 
                <UiTable
                    loading={props.loading}
                    className={'admin-table ' + props.className}
                    sorted
                    sortColumn={0}
                    items={itemList}
                    tableHeads={heads}
                    rowPerPages={adminRowsPerPage}
                />
            }

        </UiBox>
    );
};
