import React, { useState, MouseEvent } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import "./ImportHistoryList.scss";
import CachedIcon from "@material-ui/icons/Cached";

import { TImportedFile } from "../../../shared/services/massImportLVL1/massImportService";
import moment from "moment";

interface ImportHistoryListProps {
    history: TImportedFile[];
    onRollback: (file: TImportedFile) => void;
    isLoading: boolean;
    error: string | null;
    onRefresh: () => void;
}

const ImportHistoryList: React.FC<ImportHistoryListProps> = ({ history, onRollback, isLoading, error, onRefresh }) => {
    const [selectedFile, setSelectedFile] = useState<TImportedFile | null>(null);

    const handleRollbackClick = (file: TImportedFile) => {
        setSelectedFile(file);
    };

    const handleConfirmRollback = async () => {
        if (selectedFile) {
            await onRollback({
                ...selectedFile,
                isImportRollbacked: true,
            });
            setSelectedFile(null);
            onRefresh();
        }
    };

    const handleCancelRollback = () => {
        setSelectedFile(null);
    };

    if (isLoading) {
        return (
            <div className="drag-and-drop__loading">
                <CachedIcon className="drag-and-drop__loading-icon" />
                <p>Loading...</p>
            </div>
        );
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <section className="import-history-list">
            {history.length > 0 ? (
                history.map((file) => (
                    <div
                        key={file.id}
                        className={`import-history-item-container ${
                            selectedFile && selectedFile.id !== file.id ? "disabled" : ""
                        }`}
                    >
                        {selectedFile?.id === file.id && (
                            <Typography variant="body1" className="warning-message">
                                You are about to rollback {file.eventsCount} imported events.
                            </Typography>
                        )}
                        <div
                            className={`import-history-item ${selectedFile?.id === file.id ? "selected" : ""} ${
                                file.isImportRollbacked ? "rolledbacked" : ""
                            }`}
                        >
                            <div className="import-history-details">
                                <p>
                                    {moment(file.createdDate).format("DD/MM/yyyy, h.mm A")}
                                    {file.isImportRollbacked && <span className="rolledback-label">Rolledback</span>}
                                </p>
                                <p>
                                    {file.createdBy} &bull; {file.eventsCount} events
                                </p>
                            </div>
                            {file.isImportRollbacked ? (
                                <Button variant="outlined" color="secondary" disabled>
                                    Rollback
                                </Button>
                            ) : selectedFile?.id === file.id ? (
                                <div className="rollback-confirmation">
                                    <Button variant="outlined" color="primary" onClick={handleCancelRollback}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="button-confirm"
                                        onClick={handleConfirmRollback}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            ) : (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleRollbackClick(file)}
                                    disabled={!!selectedFile}
                                >
                                    Rollback
                                </Button>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <p>No history available.</p>
            )}
        </section>
    );
};

export default ImportHistoryList;
