import React, { MouseEvent, useRef, useState, useCallback } from "react";
import { UiModal, IPropsUiModalBase, EUiModalReason } from "../../../shared/components/ui/UiModal/UiModal";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import "./ModalImportLVL1.scss";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import StatusAlert from "./StatusAlert";
import { getAlertProps } from "./statusAlert.config";
import FooterModalImportLVL1 from "./FooterModalImportLVL1";
import {
    TAddDuplicateEventsResponse,
    TDuplicateEvent,
    TImportEventResponse,
} from "../../../shared/services/massImportLVL1/massImportService";
import ImportedDataDisplay from "./ImportedDataDisplay";
import { useHistory } from "react-router-dom";

interface IModalImportLVL1Props extends IPropsUiModalBase {
    onImport: (file: File) => Promise<TImportEventResponse>;
    onDuplicateSelection: (selectedEvents: TDuplicateEvent[]) => Promise<TAddDuplicateEventsResponse>;
}

export type TImportState =
    | {
          type: "import";
          response: TImportEventResponse;
      }
    | {
          type: "duplicate";
          response: TAddDuplicateEventsResponse;
      }
    | null;

const ModalImportLVL1: React.FC<IModalImportLVL1Props> = ({
    onImport,
    onDuplicateSelection,
    onClose,
    open,
    ...rest
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [importState, setImportState] = useState<TImportState>(null);
    const [selectedDuplicates, setSelectedDuplicates] = useState<TDuplicateEvent[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const navigation = useHistory();

    const handleImport = async (file: File) => {
        setIsLoading(true);
        setError(undefined);

        try {
            const response = await onImport(file);
            console.log("Import Response:", response);
            setImportState({ type: "import", response });
        } catch (error) {
            console.error("Error during import:", error);
            setError("An error occurred during import. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDuplicateSelectionChange = useCallback((selectedEvents: TDuplicateEvent[]) => {
        setSelectedDuplicates(selectedEvents);
    }, []);

    const handleDuplicateSubmit = useCallback(async () => {
        if (selectedDuplicates.length === 0) return;

        setIsLoading(true);
        setError(undefined);

        try {
            const response = await onDuplicateSelection(selectedDuplicates);
            console.log("Duplicate events added:", response);
            setImportState({ type: "duplicate", response });
        } catch (error) {
            console.error("Error adding duplicate events:", error);
            setError("An error occurred while adding duplicate events. Please try again.");
        } finally {
            setIsLoading(false);
        }
    }, [selectedDuplicates, onDuplicateSelection]);

    const handleClose = useCallback(
        (event: {} | MouseEvent<HTMLElement> | KeyboardEvent, reason: EUiModalReason) => {
            onClose?.(event, reason);

            if (reason === EUiModalReason.TryAgainBtnClickj || reason === EUiModalReason.Import) {
                setImportState(null);
                setError(undefined);
                setSelectedDuplicates([]);
                navigation.go(0);
            }
        },
        [onClose]
    );

    const alertProps = getAlertProps(importState?.response ?? null);
    const footerStatusResponse = importState?.response.status ?? null;

    return (
        <UiModal
            title="Import LV1 events only"
            open={open}
            onClose={handleClose}
            footerButtons={[
                <FooterModalImportLVL1
                    key="footer"
                    statusResponse={footerStatusResponse}
                    responseType={importState?.type ?? null}
                    onClose={handleClose}
                    setImportResponse={setImportState}
                    selectedDuplicates={selectedDuplicates}
                    onDuplicateSubmit={handleDuplicateSubmit}
                    isLoading={isLoading}
                />,
            ]}
            className="modal-import-lvl1"
            {...rest}
        >
            {alertProps ? <StatusAlert {...alertProps} /> : null}

            {!importState ? (
                <>
                    <section>
                        <p>
                            This feature enables to import a batch of LV1 events. To use it, upload an Excel file.
                            Please download and use the provided template.
                        </p>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<GetAppIcon />}
                            className="modal-import-lvl1__download-button"
                            href="/documents/template_events_import.xlsx"
                        >
                            Download Template (.xls)
                        </Button>
                    </section>
                    <DragAndDrop
                        onFileDrop={handleImport}
                        accept=".xls,.xlsx"
                        externalLoading={isLoading}
                        externalError={error}
                        onLoadingChange={setIsLoading}
                        onErrorChange={setError}
                        inputRef={fileInputRef}
                    />
                </>
            ) : importState.type === "import" ? (
                <ImportedDataDisplay
                    data={importState.response}
                    onDuplicateSelection={handleDuplicateSelectionChange}
                />
            ) : null}
        </UiModal>
    );
};

export default ModalImportLVL1;
